.student-info-card {
    background-color: #f8f9fa;
    border: none;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.student-avatar {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    object-fit: cover;
}

.student-details {
    flex-grow: 1;
}

.payment-timeline {
    position: relative;
    padding-left: 30px;
}

.payment-item {
    position: relative;
    padding-bottom: 20px;
}

.payment-status {
    position: absolute;
    left: -30px;
    top: 0;
    bottom: 0;
    width: 20px;
}

.status-dot {
    position: absolute;
    top: 5px;
    left: 0;
    width: 12px;
    height: 12px;
    border-radius: 50%;
}

.status-line {
    position: absolute;
    top: 17px;
    bottom: 0;
    left: 5px;
    width: 2px;
    background-color: #e0e0e0;
}

.payment-content {
    padding-left: 10px;
}

.payment-amount {
    font-weight: bold;
}

.payment-note {
    display: block;
    margin-top: 5px;
    color: #6c757d;
}

/* Status colors */
.status-dot.paid, .payment-amount.paid { color: #28a745; }
.status-dot.paid { background-color: #28a745; }