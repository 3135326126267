* body {
    color: black;
    background-color: #F6F7FF;
}

html {
    font-size: 1rem;
    scroll-behavior: smooth;
}


/* Override default */
.Draftail-Toolbar {
    background: transparent !important;
    border: none !important;
}

/* Override default */
.Draftail-Editor {
    border: none !important;
    width: 60% !important;
    margin-top: 100px !important;
    margin-left: auto !important;
    margin-right: auto !important;
}

/* Override default */
.public-DraftEditor-content {
    font-family: Georgia, "Times New Roman", Times, serif !important;
}

/* Override default */
.public-DraftEditorPlaceholder-inner {
    font-family: Georgia, "Times New Roman", Times, serif !important;
}